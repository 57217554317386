import { render, staticRenderFns } from "./eds-iit-checkbox.vue?vue&type=template&id=8f2f2db0&scoped=true&lang=pug&"
import script from "./eds-iit-checkbox.vue?vue&type=script&lang=js&"
export * from "./eds-iit-checkbox.vue?vue&type=script&lang=js&"
import style0 from "./eds-iit-checkbox.vue?vue&type=style&index=0&id=8f2f2db0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f2f2db0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VForm,VRadio,VRadioGroup,VSelect})
